jQuery(document).ready(function () {

  jQuery('.a467 #faqs .panel-group .panel .panel-heading .panel-title a').on('click', function(event){
    event.preventDefault();
        // Stop propagation of the event
        event.stopPropagation();
        // Optionally, you can also prevent any other handlers on the same element from being triggered
        event.stopImmediatePropagation();
  });
  jQuery('.a467 #faqs .panel-group .panel').on('click', function(event){
    event.preventDefault();
        // Stop propagation of the event
        event.stopPropagation();
        // Optionally, you can also prevent any other handlers on the same element from being triggered
        event.stopImmediatePropagation();
  });
  jQuery(document).on('click', '.menu-item  a[href^="#"]', function (event) {
    event.preventDefault();

    jQuery('html, body').animate({
        scrollTop: jQuery(jQuery.attr(this, 'href')).offset().top - 100
    }, 500);
  });

  jQuery('.menu-smart > li').on('click', function(){
    jQuery('.mobile-menu-button').trigger('click');
  })

  // Detect before and after position
  const source = document.querySelector(".twentytwenty-container");
  source.addEventListener("click", (event) => {
      let isShown = true;
      if(isShown){
        source.classList.add('dragged');
      }
  });

  // dowload-app-section

  jQuery('#google-play-btn-dowload-app-section a').on('click', function(){
    gtag("event", "app_btn", {
      "section_name": "download-app-section",
      "item": "google-play-btn"
    })
  });

  jQuery('#app-store-btn-dowload-app-section a').on('click', function(){
    gtag("event", "app_btn", {
      "section_name": "download-app-section",
      "item": "app-store-btn"
    })
  });


   // footer-dowload-app-section

   jQuery('#google-play-btn-footer-section a').on('click', function(){
    gtag("event", "footer_app_btn", {
      "section_name": "footer_app_btn-section",
      "item": "google-play-btn"
    })
  });

  jQuery('#app-store-btn-footer-section a').on('click', function(){
    gtag("event", "footer_app_btn", {
      "section_name": "footer_app_btn-section",
      "item": "app-store-btn"
    })
  });

  jQuery('[id^="call-us-banner"]').on('click', function(event){
    event.preventDefault();
    gtag("event", "call_us_hero", {
      "section_name": "hero",
      "item": "call_us"
    })
  });

  jQuery('[id^="know-more-banner"]').on('click', function(event){
    event.preventDefault();
    gtag("event", "know_more_hero", {
      "section_name": "hero",
      "item": "know_more"
    })
  });

   // dowload-app-section

  jQuery('#menu-main-menu .menu-item').on('click', function(e){
    var title = jQuery(this).find('a').text();
   
    gtag("event", "main_menu", {
      "section_name": "Menu",
      "item": title
    })
    
  });

  jQuery('.newsfeed .owl-item').on('click', function(event){
    var title = jQuery(this).find('.t-entry-title a').text();
   
    gtag("event", "newsfeed", {
      "section_name": "newsfeed",
      "item": title
    })
  });

  jQuery('.panel-group .panel').on('click', function(event){
    var title = jQuery(this).find('.panel-title a').text();
   
    gtag("event", "faqs", {
      "section_name": "faqs",
      "item": title
    })
  });






});
